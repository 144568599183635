import { useAuth } from 'src/hooks/useAuth';
import { useContext } from 'react'; // Import useContext
import { AuthContext } from 'src/contexts/FirebaseAuthContext'; // Update the path as necessary
import {Box, Typography,useTheme} from '@mui/material';
import { useRouter } from 'next/router';

function SidebarTopSection() {
  
  const theme = useTheme();
  const { user } = useContext(AuthContext); // Use useContext to access the AuthContext

  const userName = user?.nombres + " " + user?.apellidos || 'User';

  const handleClose = () => {
    setOpen(false);
  };


  return (
    <Box
      sx={{
        textAlign: 'center',
        mx: 2,
        pt: 1,
        position: 'relative'
      }}
    >

      <Typography
        variant="h4"
        sx={{
          color: `${theme.colors.alpha.trueWhite[100]}`
        }}
      >
        {userName}
      </Typography>
    </Box>
  );
}

export default SidebarTopSection;
