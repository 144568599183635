import {BarChartTwoTone} from "@mui/icons-material";
import StoreIcon from '@mui/icons-material/Store';
import HomeIcon from '@mui/icons-material/Home';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined';
import SavingsOutlinedIcon from '@mui/icons-material/SavingsOutlined';


const menuItems = (agentType) => [
  {
    items: [
      {
        name: "Inicio",
        link: "/",
        icon: HomeIcon,
      },
      ...(agentType == 'Agente' ? [{
        name: "Caja",
        link: "/management/recargas",
        icon: PointOfSaleIcon,
      },
      {
        name: "Actas",
        link: "/management/withdrawals",
        icon: ReceiptLongOutlinedIcon,
      },
      {
        name: "Comisiones",
        link: "/management/comissions",
        icon: SavingsOutlinedIcon,
      },
    ] : []),

      ...(agentType !== 'Agente' ? [{
        name: "Gestion de Agentes",
        icon: StoreIcon,
        link: "/management/agents/tree",
        },
        {
            name: "Actas",
            link: "/management/withdrawals",
            icon: ReceiptLongOutlinedIcon,
          },
        {
            name: "Comisiones",
            link: "/management/comissions",
            icon: SavingsOutlinedIcon,
          },
    ] : [])
    ],
  }
];

export default menuItems;