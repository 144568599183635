import * as React from 'react';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import Paper from '@mui/material/Paper';
import { useMediaQuery, useTheme } from '@mui/material';
import Link from 'next/link';
import menuItems from './items'; // Adjust the import path
import useAgentStore from 'src/hooks/UseStore';

export default function FixedBottomNavigation() {
  const [value, setValue] = React.useState(0);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const agentType = useAgentStore((state) => state.agentType);
  const items = menuItems(agentType)[0].items;

  const flattenedItems = items.flatMap(item =>
    item.items ? item.items : item
  ).filter(item => item.link);

  if (!isMobile) {
    return null;
  }

  return (
    <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
      <BottomNavigation
        //showLabels
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
      >
        {flattenedItems.map((item, index) => (
          <BottomNavigationAction
            key={index}
            label={item.name}
            icon={<item.icon />}
            component={Link}
            href={item.link}
          />
        ))}
      </BottomNavigation>
    </Paper>
  );
}
