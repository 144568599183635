import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import { useAuth } from 'src/hooks/useAuth'; // Ensure this hook provides access to the current auth state
import { useSnackbar } from 'notistack';
import { Slide } from '@mui/material';

export const Authenticated = (props) => {
  const { children } = props;
  const auth = useAuth(); // This hook must be providing the current authentication state
  const router = useRouter();
  const [verified, setVerified] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    // Removed the router.isReady check as we're now also depending on auth.isAuthenticated
    // This means the effect will run on initial mount and whenever auth.isAuthenticated changes
    if (!auth.isAuthenticated) {
      // Redirect unauthenticated users to the login page
      router.push({
        pathname: '/auth/login/basic',
        //query: { backTo: router.asPath }
      });
    } else {
      // Set verified to true to render the children components if the user is authenticated
      setVerified(true);
      
      // Optionally, show a success snackbar on successful authentication
      // Consider if you want this to appear every time auth state changes
      // enqueueSnackbar('You are successfully authenticated!', {
      //   variant: 'success',
      //   anchorOrigin: {
      //     vertical: 'bottom',
      //     horizontal: 'right'
      //   },
      //   autoHideDuration: 2000,
      //   TransitionComponent: Slide
      // });
    }
  }, [auth.isAuthenticated, router, enqueueSnackbar]); // Added auth.isAuthenticated as a dependency

  if (!verified) {
    return null; // Optionally, render a loader or any placeholder while checking auth state
  }

  return <>{children}</>;
};

Authenticated.propTypes = {
  children: PropTypes.node
};
