import { Box, useTheme } from "@mui/material";
import PropTypes from "prop-types";
import Sidebar from "./Sidebar";
import MobileNavigation from "./MobileNavigation"
import Header from "./Header";
import Footer from "src/components/Footer";
import { useContext } from "react";
import { SidebarContext } from "src/contexts/SidebarContext";


const ExtendedSidebarLayout = ({ children }) => {
  const { sidebarToggle } = useContext(SidebarContext);
  const theme = useTheme();

  return (
    <>
      <Box
        sx={{
          display: "flex",
          height: "100%",
        }}
      >
        <Header/>
        <Sidebar/>
        <Box
          marginLeft={sidebarToggle ? theme.sidebar.width : 0}
          
          sx={{
            position: 'relative',
            zIndex: 5,
            display: 'block',
            flex: 1,
            pt: `${theme.header.height}`
        
          }}
        >
          <Box display="block">{children}</Box>
          <MobileNavigation/>
          {/* <Footer /> */}
        </Box>
      </Box>
    </>
  );
};

ExtendedSidebarLayout.propTypes = {
  children: PropTypes.node,
};

export default ExtendedSidebarLayout;
